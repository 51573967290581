export function getStrapiURL(path = "") {
  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL || "https://iskibris-strapi.herokuapp.com"}${path}`
}

export function getIskibrisUrl(path = "") {
  return `${process.env.NEXT_PUBLIC_ISKIBRIS_API_URL || "https://staging.iskibris.com"}${path}`
}

// Helper to make GET requests to Strapi
export async function fetchAPI(path) {
  const requestUrl = getStrapiURL(path)
  const response = await fetch(encodeURI(requestUrl))
  const data = await response.json()
  return data
}

export async function fetchEmployersAPI(path) {
  const requestUrl = getIskibrisUrl(path)
  const response = await fetch(encodeURI(requestUrl))
  const data = await response.json()
  return data
}
