const useGtm = () => {
  const tagEvent = ({ event, data }) => {
    if (typeof window !== "undefined") {
      window.dataLayer.push({
        event: event,
        ...data
      })
    }
  }

  return { tagEvent: tagEvent }
}

export default useGtm
